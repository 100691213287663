import Vue from 'vue' 
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/dashboard/Dashboard')
const MyTask = () => import('@/views/task/MyTask')
const InsightTank = () => import('@/views/insight/InsightTank.vue')
const ConceptTank = () => import('@/views/concept/ConceptTank.vue')
const IdeaTank = () => import('@/views/idea/IdeaTank.vue')
const History = () => import('@/views/history/History')
const HistoryDetail = () => import('../views/history/HistoryDetail.vue')
const conceptViewer = () => import('@/views/concept/ConceptViewer.vue')
const IdeationManager = () => import('@/views/manager/IdeationManagerList.vue')
const Viewer = () => import('@/views/viewer/Viewer.vue')

// Views - Pages
const Login = () => import('@/views/Login.vue')

Vue.use(Router)

// const IdeationToken = localStorage.getItem("IdeationToken")
const IdeationToken = localStorage.getItem("IdeationToken")

const currentRout = window.location.hash || "";
// console.log("currentRout",currentRout,currentRout.includes("Viewer"));
// console.log("currentRout",currentRout,currentRout.includes("#/task/MyTask"));
// console.log("IdeationToken",IdeationToken);

if(!currentRout.includes("Viewer")){
  
  if (IdeationToken == null || IdeationToken == "null") {

    if (window.location.hash.includes('#/task/MyTask/view/') || window.location.hash.includes('#/task/MyTask/edit/')) {
      localStorage.setItem('CurrentPath', window.location.hash);
    }
    
    window.location.href = '#/login'
  } 
  else {
    const prevAccepted = JSON.parse(IdeationToken)
    const currentTime = new Date().getTime()
    // const expirationDuration = (1000 * 60 * 60 * 24) // 24 hours
    const expirationDuration = (1000 * 60 * 60) // 1 hours

    if ((currentTime - prevAccepted.timestamp) > expirationDuration) {

      if (window.location.hash.includes('#/task/MyTask/view/') || window.location.hash.includes('#/task/MyTask/edit/')) {
        localStorage.setItem('CurrentPath', window.location.hash);
      }

      window.location.href = '#/login'
    }
  }
}

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/login',
      name: 'Login',
      component: {
        render (c) { return c('router-view') }
      }
      ,children: [
        {
            path: 'login',
            name: 'Login',
            component: Login
        }
      ]
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/IdeationManager',
          name: 'Ideation Manager',
          component: IdeationManager
        },
        {
          path: "/task/MyTask",
          name: "My Task",
          component: MyTask,
        },{
          path: "/task/MyTask/:mode/:docNo",
          name: "My Task",
          component: MyTask,
        },{
          path:"/insight/InsightTank",
          name:"Insight Tank",
          component:InsightTank,
        },{
          path:"/idea/IdeaTank",
          name:"Idea Tank",
          component:IdeaTank,
        }
        // ,{
        //   path:"/concept/ConceptTank/:Mode/:DocNo",
        //   name:"Concept Tank",
        //   component:ConceptTank,
        // }
        ,{
          path:"/concept/ConceptTank/",
          name:"Concept Tank",
          component:ConceptTank,
        }
        ,{
          path: "/history/History",
          name: "History",
          component: History,
        },
        {
          path: "/history/HistoryDetail",
          name: "History Detail",
          component: HistoryDetail,
        }
      ]
    },
    {
      path: '/conceptViewer',
      redirect: '/conceptViewer',
      name: '',
      component: {
        render (c) { return c('router-view') }
      }
      ,children: [
        {
            path: '/conceptViewer/:DocNo',
            name: 'conceptViewer',
            component: conceptViewer
        } 
      ]
    },
    {
      path: '/viewer',
      redirect: '/viewer',
      name: '',
      component: {
        render (c) { return c('router-view') }
      }
      ,children: [
        {
            path: '/viewer/:docNo',
            name: 'viewer',
            component: Viewer
        } 
      ]
    }
  ]
})
